<template>
  <div>
    <svg ref="multi-country-map-d3"></svg>
    <div ref="multi-country-map-d3-tooltip" class="tooltip-map"></div>
    <div ref="legends" class="legends"></div>
    <div class="zoom">
      <div ref="zoom-in" class="in">+</div>
      <div ref="zoom-out" class="out">-</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import usStatesData from './us-states.json';
import usDmasData from './us-dmas-nielsen.json';
import auStatesData from './au-states.json';
import formatNumber from '~/util/numeral';

const width = 600;
const height = 400;
const legendSpectrumWidth = 300;
const radius = 3;
const strokeWidth = 1;

const colors = ['#80cbc4', '#4db6ac', '#26a69a', '#009688', '#00897b', '#00796b'];

export default {
  name: 'MultiCountryMapChart',
  props: {
    mapCountry: {
      type: String,
      required: false,
      default: () => 'US',
    },
    mapType: {
      type: String,
      required: false,
      default: () => 'state',
    },
    mapData: {
      type: Array,
      required: false,
      default: () => [],
    },
    valueKey: {
      type: String,
      required: false,
      default: () => 'Impressions',
    },
    valueType: {
      type: String,
      required: true,
    },
  },
  data() {
    const locationTypeMap = {
      dma: 'DMA',
      address: 'Address',
      state: 'State',
    };

    const locationType = locationTypeMap[this.mapType] || 'State';

    return {
      map: null,
      tooltip: null,
      path: null,
      locationType,
    };
  },
  watch: {
    mapData(n) {
      const vm = this;

      vm.init(n);
    },
  },
  mounted() {
    const vm = this;

    vm.init();
  },
  beforeDestroy() {
    const vm = this;

    d3.select(vm.$refs['multi-country-map-d3']).remove();
    vm.map = null;
    vm.tooltip = null;
  },
  methods: {
    init() {
      const vm = this;

      vm.map = d3
        .select(vm.$refs['multi-country-map-d3'])
        .attr('width', width)
        .attr('height', height);

      const g = vm.map.append('g');

      vm.tooltip = d3.select(vm.$refs['multi-country-map-d3-tooltip']);

      const zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on('zoom', function() {
          g.selectAll('path').attr('transform', d3.event.transform);
          if (vm.mapType === 'address') {
            const scale = d3.event.transform.k;
            g.selectAll('circle')
              .attr('r', radius / scale)
              .style('stroke-width', `${strokeWidth / scale}px`)
              .attr('transform', d3.event.transform);
          }
        });

      vm.map.call(zoom);

      d3.select(vm.$refs['zoom-in']).on('click', function() {
        zoom.scaleBy(vm.map.transition().duration(200), 1.3);
      });

      d3.select(vm.$refs['zoom-out']).on('click', function() {
        zoom.scaleBy(vm.map.transition().duration(200), 1 / 1.3);
      });

      // Generate highest values all possible impressions for generating chart legend heatmap.
      const geoTally = vm.mapData
        // As we're receiving values as integers, we first convert them to float with a precision
        // of 2 values by dividing it by a factor (which 100 for values >= 100
        // and it will be 10 for values < 100)
        // Then we round up the float values to the nearest integer
        // Then we rounds the number up to the next largest integer
        // And then convert it back to the original format by finally multiplying it by the
        // same factor.
        .map((location) => {
          let roundedValue = location.tally;
          if (location.tally < 10) {
            roundedValue = Math.ceil(Math.round(location.tally));
          } else {
            const factor = location.tally < 100 ? 10 : 100;
            roundedValue = Math.ceil(Math.round(location.tally / factor)) * factor;
          }
          return roundedValue;
        })
        .sort((a, b) => a - b)
        // Here we're only fetching non-zero values as we specifically define the first
        // legend as "0"
        .filter((f) => f !== 0);

      // Find last legend value
      const max = d3.max(geoTally);
      let factor = 0;
      if (max < 10) {
        factor = 1;
      } else {
        factor = max < 100 ? 10 : 100;
      }
      const lastLegendValue = max + factor;

      // Find mid point of last value
      const middleLegendValue = lastLegendValue / 2;

      const legend = d3
        .select(vm.$refs.legends)
        .html('')
        .attr('style', `width: ${width}px;`)
        .attr('style', `margin: auto`)
        .append('svg:svg')
        .attr('width', legendSpectrumWidth + 50)
        .attr('height', 40);

      // This function adds legend ticks for provided x-position and the tick value to be
      // displayed
      function addLegendTick(x, tickValue) {
        const tick = legend.append('g');

        tick
          .append('svg:rect')
          .attr('x', x)
          .attr('y', 5)
          .attr('height', 15)
          .attr('width', 1)
          .attr('fill', 'rgba(130, 130, 140, 0.8)');

        tick
          .append('text')
          .attr('x', x === 0 ? 0 : x - 4)
          .attr('y', 32)
          .attr('font-size', 10)
          .attr('fill', 'rgba(130, 130, 140, 0.8)')
          .text(formatNumber(tickValue, vm.valueType));
      }

      // Append a defs (for definition) element to your SVG
      const defs = legend.append('defs');

      // Append a linearGradient element to the defs and give it a unique id
      const linearGradient = defs
        .append('linearGradient')
        .attr('id', 'linear-gradient')
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '100%')
        .attr('y2', '0%');

      // Set the color for the start (0%)
      linearGradient
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', colors[0]);

      // Set the color for the end (100%)
      linearGradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', colors[colors.length - 1]);

      // First tick with 0 value
      addLegendTick(0, 0);

      // Last tick with max value
      addLegendTick(legendSpectrumWidth + 1, lastLegendValue);

      // Draw the rectangle and fill with gradient
      legend
        .append('rect')
        .attr('x', 1)
        .attr('y', 10)
        .attr('width', legendSpectrumWidth)
        .attr('height', 10)
        .style('fill', 'url(#linear-gradient)');

      // Middle tick with max value
      addLegendTick(legendSpectrumWidth / 2, middleLegendValue);

      const projection = vm.getD3Projection();

      vm.path = d3.geoPath().projection(projection);

      if (vm.mapType === 'address') {
        vm.plotAddressMap(vm.mapData, projection);
      } else {
        vm.plotMap(vm.mapData);
      }
    },
    getD3Projection() {
      if (this.mapCountry === 'AU') {
        return d3
          .geoMercator()
          .center([135, -28])
          .translate([width / 2, height / 2]) // translate to center of screen
          .scale([560]);
      }
      return d3
        .geoAlbersUsa()
        .translate([width / 2, height / 2]) // translate to center of screen
        .scale([800]);
    },
    getGeoLocations(country, mapType) {
      if (country === 'AU') {
        return JSON.parse(JSON.stringify(auStatesData));
      }
      return JSON.parse(JSON.stringify(mapType === 'dma' ? usDmasData : usStatesData));
    },
    plotMap(mapData) {
      const vm = this;
      const tallyValues = [];

      // Convert array to key-value pair
      const geoTallyLookup = {};
      for (let i = 0; i < mapData.length; i++) {
        const geo = mapData[i];
        geoTallyLookup[geo.location] = geo.tally;
      }

      let { mapType } = vm;
      if (vm.mapCountry === 'AU' && mapType === 'dma') {
        mapType = 'state';
      }

      // Reload GeoJSON data
      const geoLocations = vm.getGeoLocations(vm.mapCountry, mapType);

      let propertyName;
      if (vm.mapCountry === 'AU') {
        propertyName = 'STATE_NAME';
      } else {
        propertyName = mapType === 'dma' ? 'dma' : 'name';
      }

      // Lookup location in key-value pair, if found update the value
      for (let j = 0; j < geoLocations.features.length; j++) {
        const feature = geoLocations.features[j];
        const location = feature.properties[propertyName];
        const tally = geoTallyLookup[location];
        if (tally) {
          feature.properties.location = mapType === 'dma' ? feature.properties.dma1 : location;
          feature.properties.tally = tally;
          tallyValues.push(tally);
        }
      }

      const maxTally = tallyValues.length ? d3.max(tallyValues) : 0;
      const totalTally = d3.sum(tallyValues); // Compute total tally for percentage calculation

      vm.map
        .select('g')
        .selectAll('path')
        .data(geoLocations.features)
        .enter()
        .append('path')
        .attr('d', vm.path)
        .style('fill', function(d) {
          const { tally } = d.properties;
          const colorScale = d3
            .scaleLinear()
            .domain([0, maxTally])
            .range([colors[0], colors[colors.length - 1]]);

          if (tally) {
            return colorScale(tally);
          }
          return 'rgb(156,229,228)';
        });

      vm.$refs['multi-country-map-d3'].addEventListener('mouseover', function mouseoverEv(e) {
        if (!e.target.__data__) {
          return;
        }
        const d = e.target.__data__;
        if (d.properties.tally > 0) {
          const percentage = ((d.properties.tally / totalTally) * 100).toFixed(2);
          vm.tooltip.style('opacity', 0.95);
          vm.tooltip
            .html(
              `${vm.locationType}: ${d.properties.location}<br/>
            ${vm.valueKey}: ${formatNumber(d.properties.tally, vm.valueType)} (${percentage}%)`
            )
            .style('top', `${e.clientY - 50}px`)
            .style('left', `${e.clientX - 100}px`);
        } else {
          vm.tooltip.style('class', 'hidetooltip');
          d3.selectAll('.tooltip-map').style('opacity', '0');
        }
      });

      vm.$refs['multi-country-map-d3'].addEventListener('mouseout', function mouseoutEv() {
        vm.tooltip.style('opacity', 0);
      });
    },
    plotAddressMap(mapData, projection) {
      const vm = this;

      // Reload GeoJSON data
      const geoLocations = vm.getGeoLocations(vm.mapCountry, vm.mapType);

      const tallyValues = vm.mapData.map((e) => e.tally);
      const maxTally = d3.max(tallyValues);
      const totalTally = d3.sum(tallyValues); // Compute total tally for percentage calculation

      vm.map
        .select('g')
        .selectAll('path')
        .data(geoLocations.features)
        .enter()
        .append('path')
        .attr('d', vm.path)
        .style('fill', 'rgb(156,229,228)');

      vm.map
        .select('g')
        .selectAll('circle')
        .data(vm.mapData)
        .enter()
        .append('circle')
        .attr('cx', function(d) {
          return projection([d.lng, d.lat])[0];
        })
        .attr('cy', function(d) {
          return projection([d.lng, d.lat])[1];
        })
        .attr('r', radius)
        .style('fill', function(d) {
          const { tally } = d;
          const colorScale = d3
            .scaleLinear()
            .domain([0, maxTally])
            .range([colors[0], colors[colors.length - 1]]);

          if (tally) {
            return colorScale(tally);
          }
          return 'rgb(27,197,184)';
        })
        .style('stroke-width', `${strokeWidth}px`)
        .style('stroke', 'rgb(0,164,138)')
        .style('opacity', 0.85)
        .on('mouseover', function(d) {
          const el = d3.select(this);
          const pos = el.node().getBoundingClientRect();
          const px = pos.x;
          const py = pos.y - 50;

          const percentage = ((d.tally / totalTally) * 100).toFixed(2);
          vm.tooltip.style('opacity', 0.95);
          vm.tooltip
            .html(
              `${vm.locationType}: ${d.location}<br/>
            ${vm.valueKey}: ${formatNumber(d.tally, vm.valueType)} (${percentage}%)`
            )
            .style('left', `${px}px`)
            .style('top', `${py}px`);
        })
        .on('mouseout', function() {
          vm.tooltip.style('class', 'hidetooltip');
          d3.selectAll('.tooltip-map').style('opacity', '0');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.legends {
  padding: 0.5rem;
  text-align: center;
}
.tooltip-map {
  position: fixed;
  min-width: 150px;
  min-height: 40px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  pointer-events: none;
  border-radius: 3px;
  opacity: 0;
  transition: all 0.2s ease;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  background-color: rgba(44, 48, 54, 0.98);
  font-family: 'Manrope', sans-serif;
}
.zoom {
  position: absolute;
  top: 65%;
  left: 80%;
  float: right;
  .in {
    width: 20px;
    height: 20px;
    padding: 0px 6px;
    margin-bottom: 1px;
    cursor: default;
    background-color: #505050;
    border-radius: 2px;
    outline: none;
  }
  .out {
    width: 20px;
    height: 20px;
    padding: 0px 7px;
    cursor: default;
    background-color: #505050;
    border-radius: 2px;
    outline: none;
  }
}
</style>
